import LivingMap, { FilterKing, LMFeature } from "@livingmap/core-mapping";
import { useEffect, useMemo, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { useFloorData } from "../../hooks/useFloorData";
import { useRegionData } from "../../hooks/useRegionData";
import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";
import useResponsive from "../../hooks/useResponsive";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  useGetGeofencesQuery,
  useGetMapConfigQuery,
} from "../../redux/services/vectormapsAPI";
import { MappedFloor } from "../../redux/services/types";
import { useRouteMatch } from "../../hooks/useRouteMatch";

import { MapContext } from "../../contexts/MapContext";
import { RouteInputFocusContext } from "../../contexts/RouteInputFocusContext";

import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { push, replace } from "../../utils/navigate";
import {
  Path,
  QueryParams,
  RouteFeature,
  RoutingPath,
} from "../../utils/types";

import Router from "../../router/Router";

import Map from "../../components/Map/Map";
import ClusteredPinPlugin from "../../components/Map/plugins/clustered-pin-control";
import FloorControl from "../../components/Map/plugins/floor-control";
import InteractionPlugin from "../../components/Map/plugins/interaction-control";
import LayerIconPlugin from "../../components/Map/plugins/layer-icon-control";
import PositionPlugin from "../../components/Map/plugins/position-control";
import RoutingPlugin from "../../components/Map/plugins/routing-control";
import { PLUGIN_IDS } from "../../components/Map/plugins/types";
import { Mobile } from "../../components/SearchTags/SearchTags.stories";

import styles from "./MapContainer.module.scss";
import { useBookmarkData } from "../../hooks/useBookmarkData";
import DebugControl from "../../components/Map/plugins/debug-control";

export default function MapContainer() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { Default } = useResponsive();

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const { data: mapData } = useGetMapConfigQuery();
  const { data: geofences, isFetching: isFetchingGeofences } =
    useGetGeofencesQuery(
      { project: mapData?.id },
      { skip: !mapData?.map_options.geofences },
    );

  const { queryParamsConfig, mapOptionsConfig, language } = useAppSelector(
    (state) => state.application,
  );

  const { mappedFloors, queryParamFloor } = useFloorData(mapData?.floors);

  const { regions } = useRegionData(mapData?.regions);
  const { bookmarks } = useBookmarkData(mapData?.bookmarks);

  const {
    isOnFeatureInformationScreen,
    isOnJourneyOverviewScreen,
    isOnJourneyScreen,
    isOnRouteScreen,
  } = useRouteMatch();

  const mapRef = useRef<LivingMap | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  // Map plugins
  const [positionControl, setPositionControl] = useState<PositionPlugin | null>(
    null,
  );
  const interactionControl = useRef<InteractionPlugin | null>(null);
  const [clusteredPinControl, setClusteredPinControl] =
    useState<ClusteredPinPlugin | null>(null);
  const [layerIconControl, setLayerIconControl] =
    useState<LayerIconPlugin | null>(null);
  const [debugControl, setDebugControl] = useState<DebugControl | null>(null);
  const [filterControl, setFilterControl] = useState<FilterKing | null>(null);
  const [routingControl, setRoutingControl] = useState<RoutingPlugin | null>(
    null,
  );
  const [floorControl, setFloorControl] = useState<FloorControl | null>(null);

  const chipContainerRef = useRef<HTMLDivElement | null>(null);

  // Desktop RouteInput state
  const [originInputFocusedState, setOriginInputFocusedState] = useState(false);
  const [destinationInputFocusedState, setDestinationInputFocusedState] =
    useState(false);

  // Desktop RouteInput refs
  const originInputRef = useRef(null);
  const originInputFocused = useRef(originInputFocusedState);
  const destinationInputRef = useRef(null);
  const destinationInputFocused = useRef(destinationInputFocusedState);

  // Feature selected when routing from clicking on the map
  const [routeFeature, setRouteFeature] = useState<RouteFeature | null>(null);

  // State for handling feature POI select
  const [selectedFeature, setSelectedFeature] = useState<LMFeature | null>(
    null,
  );

  // State for handling floor change
  const [selectedFloor, setSelectedFloor] = useState<MappedFloor | undefined>(
    queryParamFloor,
  );

  const mapStylesheet = useMemo(() => {
    const defaultStylesheet = mapData?.stylesheets.available.find(
      (sheet) => sheet.id === mapData?.stylesheets.default,
    )?.url;

    return `${defaultStylesheet}?lang=${language}`;
  }, [language, mapData?.stylesheets.available, mapData?.stylesheets.default]);

  const handleMapReady = (map: LivingMap) => {
    interactionControl.current = map.getPluginById<InteractionPlugin>(
      PLUGIN_IDS.INTERACTION,
    );

    setPositionControl(
      map.getPluginById<PositionPlugin>(PLUGIN_IDS.USER_LOCATION),
    );

    setClusteredPinControl(
      map.getPluginById<ClusteredPinPlugin>(PLUGIN_IDS.CLUSTERED_PIN),
    );

    setLayerIconControl(
      map.getPluginById<LayerIconPlugin>(PLUGIN_IDS.LAYER_ICON),
    );

    setDebugControl(map.getPluginById<DebugControl>(PLUGIN_IDS.DEBUG));

    setRoutingControl(map.getPluginById<RoutingPlugin>(PLUGIN_IDS.ROUTING));

    setFloorControl(map.getPluginById<FloorControl>(PLUGIN_IDS.FLOOR));

    setFilterControl(map.getFilterKing());

    // If map gestures are disabled in query params, disable map interactivity
    if (queryParamsConfig["ui-gestures"] === "disable") {
      map.disableMapInteractivity();
    }

    setMapLoaded(true);
  };

  // Handle feature change
  useEffect(() => {
    if (!selectedFeature) return;
    if (queryParamsConfig["ui-gestures"] === "disable") return;

    const isRoutingPath = matchPath(
      `/${RoutingPath.ROUTE}/*`,
      location.pathname || "",
    );

    const isMobileRoutingScreen = isRoutingPath && Mobile;

    const isDesktopRoutingScreen =
      isRoutingPath &&
      Default &&
      (originInputFocused.current || destinationInputFocused.current);

    const name = selectedFeature.getName();

    if (isMobileRoutingScreen || isDesktopRoutingScreen) {
      const id = selectedFeature.getId();
      const address = selectedFeature.getStreetAddress();

      setRouteFeature({ id, name, address });
      return;
    }

    const lmId = selectedFeature.getLmId();
    if (!lmId) throw new Error("No Living Map ID found for selected feature.");

    dispatch(
      push({
        pathOrLocation: `/${Path.FEATURE}`,
        newQueryParams: { [QueryParams.FEATURE_ID]: lmId },
        discardParams: [QueryParams.FEATURE_ID, QueryParams.STEP_FREE],
        state: { ...(location.state as Object) }, // need to cast to type Object as state is of type unknown
      }),
    );

    logAnalyticsEvent({
      event_type: AnalyticsEvent.MAP_FEATURE_OPEN,
      event_data: { lm_id: lmId, name },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  // Handle floor change
  useEffect(() => {
    if (!selectedFloor) return;

    dispatch(
      replace({
        pathOrLocation: location.pathname || location.pathname,
        newQueryParams: { [QueryParams.FLOOR]: selectedFloor.short_name },
        state: {
          ...(location.state ? location.state : {}),
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor]);

  // Set markers from query params if supplied
  useEffect(() => {
    if (queryParamsConfig.markers && layerIconControl) {
      const parsedMarker = queryParamsConfig.markers.split("|");

      const floorFromShortName =
        mapData &&
        mapData.floors.find((floor) => {
          return (
            floor.short_name.toLowerCase() === parsedMarker[2].toLowerCase()
          );
        });

      if (!floorFromShortName) throw new Error("Floor not found");

      const marker = {
        latitude: parseFloat(parsedMarker[0]),
        longitude: parseFloat(parsedMarker[1]),
        floor: floorFromShortName.id,
        icon: parsedMarker[3] || "highlight_pin_dot",
        offsetX: parseFloat(parsedMarker[4]) || 0,
        offsetY: parseFloat(parsedMarker[5]) || 0,
      };

      layerIconControl?.addLayerIcon(
        marker.icon,
        marker.longitude,
        marker.latitude,
        marker.floor,
        marker.offsetX,
        marker.offsetY,
      );
    }
  }, [queryParamsConfig.markers, layerIconControl, mapData]);

  useEffect(() => {
    if (debugControl) {
      if (queryParamsConfig.debug === "enable") {
        debugControl.enableDebug();
      } else {
        debugControl.disableDebug();
      }
    }
  }, [queryParamsConfig.debug, debugControl, mapData]);

  useEffect(() => {
    originInputFocused.current = originInputFocusedState;
  }, [originInputFocusedState]);

  useEffect(() => {
    destinationInputFocused.current = destinationInputFocusedState;
  }, [destinationInputFocusedState]);

  useEffect(() => {
    if (
      !isOnJourneyScreen &&
      !isOnRouteScreen &&
      !isOnFeatureInformationScreen &&
      !isOnJourneyOverviewScreen
    ) {
      routingControl?.clear();
    }
  }, [
    isOnJourneyScreen,
    isOnJourneyOverviewScreen,
    isOnRouteScreen,
    routingControl,
    isOnFeatureInformationScreen,
  ]);

  if (!mapData || !mappedFloors || !queryParamFloor)
    throw new Error("Unable to load map");

  if (mapData.access_token === null)
    throw new Error("MapBox Access Token not supplied");
  if (mapData.bearing === null) throw new Error("Map Bearing not supplied");

  // If geofences haven't loaded yet, don't render the map component yet
  if (isFetchingGeofences) return null;

  const positionOnRoute =
    mapData.routing && mapData.routing.positioning_on_route === "pin"
      ? true
      : false;

  return (
    <div className={styles.container}>
      <div ref={chipContainerRef} />
      <Map
        mapInstanceRef={mapRef}
        accessToken={mapData.access_token}
        bearing={mapData.bearing}
        center={[mapData.center.longitude, mapData.center.latitude]}
        enableUI={queryParamsConfig["ui-controls"] !== "hide"}
        enableUserLocation={
          queryParamsConfig.geolocation !== "disable" &&
          mapOptionsConfig.user_location
        }
        dataQA="map"
        extent={[
          mapData.extents.bottom_left.longitude,
          mapData.extents.bottom_left.latitude,
          mapData.extents.top_right.longitude,
          mapData.extents.top_right.latitude,
        ]}
        floor={queryParamFloor}
        mapID={mapData.id}
        mapStyle={mapStylesheet}
        zoom={mapData.zoom.default}
        maxZoom={mapData.zoom.maximum}
        minZoom={mapData.zoom.minimum}
        floors={mappedFloors}
        regions={regions}
        bookmarks={bookmarks}
        attributionHTML={mapData.attribution}
        onFeatureSelect={setSelectedFeature}
        onMapReady={handleMapReady}
        onFloorChange={setSelectedFloor}
        positionOnRoute={positionOnRoute}
        availableLanguages={mapData.languages.available}
        geofences={geofences?.data}
        searchTags={!!mapData.search_tags.length}
      />
      <MapContext.Provider
        value={{
          mapLoaded,
          positionControl,
          interactionControl: interactionControl.current,
          clusteredPinControl,
          filterControl,
          routingControl,
          floorControl,
          chipContainerRef: chipContainerRef.current,
          routeFeature,
          clearRouteFeature: () => setRouteFeature(null),
          mapCentre: mapRef.current?.getMapCentre(),
        }}
      >
        <RouteInputFocusContext.Provider
          value={{
            originInputRef,
            destinationInputRef,
            originInputFocused: originInputFocusedState,
            destinationInputFocused: destinationInputFocusedState,
            setOriginInputFocused: setOriginInputFocusedState,
            setDestinationInputFocused: setDestinationInputFocusedState,
          }}
        >
          <Router />
        </RouteInputFocusContext.Provider>
      </MapContext.Provider>
    </div>
  );
}
